import { fetchApi } from "@/utils/axios";
import http from "@/utils/axios";
import URL from "@/api/urlConfig"


//魔方列表查询
export function selectrubikpage(params) {
    return fetchApi(URL.SELECTRUBIKPAGE, params, "get")
}
//魔方管理新增
export function addrubik(data) {
    return fetchApi(URL.ADDRUBIK, data, "post", "data")
}
//魔方基本信息查询
export function selectrubikdetailsbyid(params) {
    return fetchApi(URL.SELECTRUBIKDETAILSBYID, params, "get")
}
//魔方信息编辑
export function updaterubikbyid(data) {
    return fetchApi(URL.UPDATERUBIKBYID, data, 'put')
}
//魔方删除  
export function deleterubikbyid(params) {
    return fetchApi(URL.DELETERUBIKBYID + '?id=' + params.id, null, "delete")
}
//魔方类型查询
export function selectrubiktype(params) {
    return fetchApi(URL.SELECTRUBIKTYPE, params, "get")
}
//服务列表下拉框
export function selectservicelistbyname(params) {
    return fetchApi(URL.SELECTSERVICELISTBYNAME, params, "get")
}