<template>
  <div>
    <a-page-header title="魔方组件管理" class="hearStyle" />
    <a-form layout="inline">
      <a-form-item label="魔方类型：">
        <a-select
          v-model="searchForm.rubikType"
          style="width: 200px"
          placeholder="全部"
          :getPopupContainer="(triggerNode) => triggerNode.parentNode"
        >
          <a-select-option v-for="option in typeList" :key="option.rubikType" :value="option.rubikType">
            {{ option.rubikTypeName }}
          </a-select-option>
        </a-select>
      </a-form-item>
      <span class="btnClass">
        <a-button type="primary" @click="search" style="margin-top: 3px">
          <a-icon type="search" />
          查询
        </a-button>
        <a-button style="margin-left: 15px" @click="resetSearchForm"> 重置 </a-button>
      </span>
    </a-form>
    <a-button class="mt-15" type="primary" @click="addData"> <a-icon type="plus" /> 添加 </a-button>
    <a-table
      :columns="columns"
      style="margin-top: 10px"
      :data-source="tableData"
      :rowKey="
        (record, index) => {
          return index;
        }
      "
      :pagination="pagination.total ? pagination : false"
      @change="pageChange"
      bordered
    >
      <span slot="remark" slot-scope="text, record" class="action">
        <a-tooltip>
          <template slot="title">
            {{ record.remark }}
          </template>
          <span class="field">{{ record.remark }}</span>
        </a-tooltip>
      </span>
      <span slot="operation" slot-scope="text, record" class="action">
        <a-button type="link" @click="edmit(record)">编辑</a-button>
        <a-button type="link" @click="deleteData(record)">删除</a-button>
      </span>
    </a-table>
  </div>
</template>
<script>
import * as api from "@/api/cubeModuleManage";
export default {
  data() {
    return {
      typeList: [], //魔方类型
      searchForm: {
        //筛选项
        rubikType: "", //魔方类型
      },
      //分页数据
      pagination: {
        showSizeChanger: true,
        current: 1,
        pageSize: 10,
        total: 0,
        showQuickJumper: true,
        showTotal: (total) => {
          return `共 ${total} 条`;
        },
      },
      columns: [
        { title: "魔方组标题", dataIndex: "rubikTitle", key: "rubikTitle" },
        { title: "魔方类型", dataIndex: "rubikType", key: "rubikType" },
        { title: "魔方编号", dataIndex: "rubikNumber", key: "rubikNumber" },
        { title: "创建时间", dataIndex: "createdAt", key: "createdAt" },
        {
          title: "更新时间",
          key: "updateAt",
          dataIndex: "updateAt",
        },
        {
          title: "备注",
          key: "remark",
          dataIndex: "remark",
          width: 200,
        },
        {
          title: "操作",
          dataIndex: "operation",
          key: "operation",
          scopedSlots: { customRender: "operation" },
        },
      ],
      tableData: [],
    };
  },
  async mounted() {
    await this.getSelectList();
    this.getTableList();
  },
  methods: {
    //获取魔方类型下拉值
    async getSelectList() {
      const res = await api.selectrubiktype();
      if (res.code === "200") {
        this.typeList = res.data;
        let obj = {
          rubikType: "",
          rubikTypeName: "全部",
        };
        this.typeList.unshift(obj);
      }
    },
    //点击添加打开弹出
    addData() {
      this.$router.push({
        path: "/cubeModuleManagement/detail",
      });
    },
    //编辑添加打开弹出
    edmit(val) {
      this.$router.push({
        path: "/cubeModuleManagement/detail",
        query: { id: val.id },
      });
    },
    //删除
    deleteData(record) {
      const _this = this;
      _this.$confirm({
        title: "是否确认删除？",
        content: "确认后对应组件内容及其相关数据将同步删除，请谨慎操作！",
        async onOk() {
          const res = await api.deleterubikbyid({ id: record.id });
          if (res.code === "200") {
            _this.$message.success("删除成功!");
            _this.getTableList();
          } else {
            _this.$message.error(res.msg);
          }
        },
        onCancel() {},
      });
    },
    //筛选
    search() {
      this.pagination.current = 1;
      this.pagination.pageSize = 10;
      this.getTableList();
    },
    // 筛选重置
    resetSearchForm() {
      this.searchForm.rubikType = undefined;
      this.pagination.current = 1;
      this.pagination.pageSize = 10;
      this.pagination.total = 0;
      this.getTableList();
    },
    //获取列表数据
    async getTableList() {
      let obj = {
        pageNum: this.pagination.current,
        pageSize: this.pagination.pageSize,
        ...this.searchForm,
      };
      const res = await api.selectrubikpage(obj);
      if (res.code === "200") {
        //魔方类型展示匹配
        this.typeList.forEach((item) => {
          res.data.items.forEach((element) => {
            if (item.rubikType === element.rubikType) {
              element.rubikType = item.rubikTypeName;
            }
          });
        });
        this.tableData = res.data.items;
        this.pagination.total = res.data.total;
      }
    },
    //分页操作
    pageChange(option) {
      this.pagination.current = option.current;
      this.pagination.pageSize = option.pageSize;
      this.getTableList();
    },
  },
};
</script>
<style lang="scss" scoped>
.mt-15 {
  margin-top: 15px;
}
.hearStyle {
  padding-left: 0;
  margin-bottom: 10px;
  border-bottom: 1px solid rgb(235, 237, 240);
}
.ant-table-row .ant-btn {
  padding: 0;
  margin: 0 5px;
}
</style>